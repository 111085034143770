
import { useAppStateStore } from "@/stores/appState";
import { useEventStore } from "@/stores/event";
import { useAuthStore } from "@/stores/auth";
import { useWishStore } from "@/stores/wish";
import { usePreweddingStore } from "@/stores/prewedding";
import { useTemplateStore } from "@/stores/template";
import { usePromptpayStore } from "@/stores/promptpay";
import { useUserStore } from "@/stores/user";
import { useFontStore } from "@/stores/font";
import { i18n } from '~/plugins/i18n'
import useLine from "@/composables/useLine"

export const useBootstrapStore = defineStore("bootstrap", {
  state: () => ({
    isInit: false,
    failed : false
  }),
  getters: {},
  actions: {
    async init() {   

      if(this.isInit) {
        return
      }

      try {
        const authStore = useAuthStore();
        const eventStore = useEventStore();
        let u = await authStore.main();
         
        await this.main()

        if(!eventStore?.eventId) {
          this.isInit = false;
          //navigateTo("/auth/validate");
          return
        }
        this.isInit = true;
      
        //WHEN SIGNOUT INVITE THEN NOT FOUND EVENT TO REDIRECT
        if (authStore.user && authStore.user.eventList.length == 0) {
          navigateTo("/events");
          return;
        }
      }
      catch (error) {        
        const liff = await useLine().init() 
        liff.login()
        this.failed = true;
        // location.reload();
      }

    },

    async main() {
      const eventStore = useEventStore();
      const wishStore = useWishStore();
      const preweddingStore = usePreweddingStore();
      const promptpayStore = usePromptpayStore();
      const templateStore = useTemplateStore();
      const userStore = useUserStore();
      const fontStore = useFontStore();

      const storageLang = window.localStorage.getItem('lang')

      try {
        await eventStore.main();
        eventStore.fetchEventRealtime();
        
        templateStore.fetchTemplateData()
        promptpayStore.fetchPromptpayRealtime();
        wishStore.fetchWishesRealtime();
        wishStore.fetchSortedWishesRealtime();

        preweddingStore.main();
        userStore.getUsersByEventId()
        await fontStore.initGoogleFonts()

        if(storageLang) {
          i18n.global.locale.value = storageLang
        }
      } catch (error) {
        // location.reload();
      }

    },

    async mainDesktop() {
      const eventStore = useEventStore();
      const preweddingStore = usePreweddingStore();
      const wishStore = useWishStore();

      try {
        await eventStore.main();
        eventStore.fetchEventRealtime();
        preweddingStore.main();
        wishStore.fetchWishesRealtime();
        wishStore.fetchSortedWishesRealtime();
      } catch (error) {
        location.reload();
      }
    },

    async mainEventBrand(eventId: string) {
      const eventStore = useEventStore();
      const wishStore = useWishStore();

      try {
        eventStore.eventId = eventId;

        eventStore.fetchEventRealtime();
        wishStore.fetchWishesRealtime();
        wishStore.fetchSortedFavoriteWishesRealtime();
      } catch (error) {
        location.reload();
      }
    },

    onUnmound() {
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      const wishStore = useWishStore();
      const promptpayStore = usePromptpayStore();
      const templateStore = useTemplateStore();

      authStore.unSubscribeAuth();
      eventStore.unSubscribeEvent();
      templateStore.unSubscribeTemplate()
      promptpayStore.unSubscribePromptpay();
      wishStore.unSubscribeWish();
    }
  },
});
